import React from 'react'
import Helmet from 'react-helmet'

const titleTemplate = "%s - FSM"

const Seo = ({ title, link = "https://www.fsm.am", description = "Some description here" }) => {
  return (
    <Helmet titleTemplate={titleTemplate}>
      {/* <!-- Primary Meta Tags --/> */}
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta name="description" content={description} />

      {/* <!-- Open Graph / Facebook --> */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={link} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      {/* <meta property="og:image" content={imagePath} /> */}

      {/* <!-- Twitter --> */}
      <meta property="twitter:url" content={link} />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      {/* <meta property="twitter:image" content={imagePath} /> */}
      {/* <meta property="twitter:card" content={imagePath} /> */}

      <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no, viewport-fit=cover"/>
    </Helmet>
  )
}

export default Seo
