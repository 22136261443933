import { graphql, useStaticQuery } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next'

const usePrismicNews = () => {
    const { language } = useI18next()
    const newLang = language === 'en' ? 'en-us' : language === 'ru' ? 'ru' : 'hy-am'

    const response = useStaticQuery(graphql`
    query usePrismicNews {
       allPrismicNewsreports(filter: {tags: {in: "news_reports"}}) {
        nodes {
          lang
          data {
            header {
              text
            }
            linktext
            showmore
            body1 {
              ... on PrismicNewsreportsDataBody1NewsAndUsefulLinks {
                id
                slice_type
                primary {
                  id {
                    text
                  }
                  title1 {
                    text
                  }
                  short_description {
                    richText
                  }
                  content {
                    richText
                  }
                  cover {
                    url
                  }
                  cover_big {
                    url
                  }
                  useful_link_title {
                    text
                  }
                  useful_link_description {
                    richText
                  }
                  date
                }
                items {
                  link_title
                  link_href {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
    return sanitize(response.allPrismicNewsreports.nodes.filter(item => item.lang === newLang)[0].data)
};

const sanitize = (response) => {
    return {
        title: response.header.text,
        linkText: response.linktext,
        showMore: response.showmore,
        news: response.body1.map(news => ({
            id: news.primary.id.text,
            short_description: news.primary.short_description.richText,
            title: news.primary.title1.text,
            image: news.primary.cover.url,
            imageBig: news.primary.cover_big.url,
            date: news.primary.date,
            content: news.primary.content.richText,
            usefulLinks: {
                id: news.primary.id,
                title: news.primary.useful_link_title.text,
                description: news.primary.useful_link_description.richText,
                links: news.items.map(link => ({
                    title: link.link_title,
                    href: link.link_href.url
                }))
            }
        })),

    }
}

export default usePrismicNews;