import React from 'react'
import { Box } from '@chakra-ui/react'
import { Trans, Link } from 'gatsby-plugin-react-i18next'

const SmallNav = ({children}) => (
  <Box
    display="block"
    as="span"
    fontSize="xs"
    color="grey.100"
    mt="5">
    <Link to="/">
      <Trans>Home</Trans>
    </Link>
    {' '}
    /
    {' '}
    {children}
  </Box>
)

export default SmallNav
