import React from "react";
import { Box } from "@chakra-ui/react";

const changeDateFormat = (date) => {
  const getDate = new Date(date);
  const convertedDate =
    (getDate.getDate() > 9 ? getDate.getDate() : "0" + getDate.getDate()) +
    "." +
    (getDate.getMonth() > 8
      ? getDate.getMonth() + 1
      : "0" + (getDate.getMonth() + 1)) +
    "." +
    getDate.getFullYear();

  return convertedDate;
};

export default function ConvertDate({ date }) {
  return (
    <Box
      fontFamily="Mardoto"
      justifyContent="center"
      alignItems="center"
      w="92px"
      color="grey.100"
      fontSize="12px"
    >
      {changeDateFormat(date)}
    </Box>
  );
}
