import React from "react";
import { graphql } from "gatsby";
import { Flex, Container, Heading, SimpleGrid, Button } from "@chakra-ui/react";
import ProductCard from "../../components/productcard";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";
import Seo from "../../components/SEO";
import Main from "../../components/layouts/Main";
import SmallNav from "../../components/smallNav";
import usePrismicNews from "../../hooks/usePrismicNews";

const News = () => {
  const { language } = useTranslation();
  const news = usePrismicNews();
  return (
    <Main>
      <Container maxW="7xl" mb="13.5rem">
        <Seo
          title={news.title}
          description={news.title}
          link={`https://www.fsm.am/${language}/{t("news")}`}
        />
        <SmallNav>
          
            <Link to="/news-reports">{news.linkText}</Link> /
          {news.title}
          
        </SmallNav>
        <Heading
          fontFamily="Mardoto"
          as="h2"
          fontSize={{ base: "2xl", md: "3xl" }}
          mt="1.25rem"
          mb="3.81rem"
        >
          {news.title}
        </Heading>
        <SimpleGrid columns={{ base: "1", md: "3" }} spacing={2}>
          {news.news?.map((item, idx) => (
              <ProductCard
                  key={`news-${item.title}-${idx}`}
                  idNews={item.id}
                  hrefNews="single-news"
                  moreButton
                  minW="325px"
                  src={item.image}
                  product
                  date
                  mb="2rem"
                  cardDate={item.date}
                  borderRadius="24px"
                  title={item.title}
                  description={item.short_description}
              />
          ))}
        </SimpleGrid>
      </Container>
    </Main>
  );
};

export default News;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
