import React, { useMemo } from 'react';
import {
    Heading as ChakraHeading,
} from '@chakra-ui/react';
import { Link } from 'gatsby-plugin-react-i18next';

export default function Heading({
    title,
    href,
    idNews,
    hrefNews,
}) {
    const props = useMemo(() => {
        if (null !== href && undefined !== href && null === idNews) {
            return {
                as: Link,
                to: href,
                target: '_blank',
            }
        } else if (null !== idNews) {
            return {
                as: Link,
                to: `/${hrefNews}?id=${idNews}`,
                target: '_blank',
            }
        }

        return {};
    }, [href, idNews, hrefNews])

    return (
        <ChakraHeading
            fontFamily="Mardoto"
            minH="68px"
            noOfLines={3}
            lineHeight="1.33"
            fontSize="lg"
            {...props}>
            {title}
        </ChakraHeading>
    )
}
